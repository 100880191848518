/* --> Couleur
blue : #112458
blanc : #FFFFFF
*/

/*
--> Font 
font-family: 'Montserrat', sans-serif;
 */


/* App */
body {
  background-color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}


/* background */
.bg-white {
  background-color: #FFFFFF;
}

.bg-gray {
  background-color: #292a2d;
}

.bg-lightgray {
  background-color: #EAEAEA;
}

.bg-today {
  background-color: rgb(119, 176, 176);
}

/* login page */
.login {
  min-height: 100vh;
}

.login-heading {
  font-weight: 300;
}
  
.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

#forgottenPass a:hover {
  text-decoration: underline;
}

.bg-bg-image {
padding: 0px!important;
}


.bg-image {
  background-image: url('/public/build/images/bg-login.png');
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100vh;
}



.bg-first {
  background-color: #112458;
}

#bg-app {
  z-index: -1; 
  width: 100%;
  height: 100%;
  background-image: url('/public/build/images/fond.webp');
  background-position: center;
}



/* Navbar */
.link-nav {
  font-weight: 600;
}

.link-nav:hover {
  text-decoration: underline;
}

#shadow-nav {
  box-shadow:3px -1px 6px -1px rgba(0,0,0,0.45);
}

.actived {
  color: #0B5ED7!important;
}

.activ {
  background-color: #0B5ED7;
}

.hr {
  background-color: #FFFFFF;
  width: 100%;
}

.hr-dash {
  background-color: #000000;
  width: 100%;
  margin: 0.5rem;
}

/* Message */
.scroll {
  max-height: 80vh;
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
}
/* Planning */
#scroll {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  user-select: none;
}

#action-schedule {
  color: #000000;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  user-select: none; 
  -ms-user-select: none; 
  -moz-user-select: none; 
  -khtml-user-select: none; 
  -webkit-user-select: none; 
  -webkit-touch-callout: none; 
}

#action-schedule .page-link {
  color: #000000!important;
  font-weight: 600;
}

@media only screen and (min-width: 1023px) {
 #currentMonth {
  min-width: 155px;
 }
}


/* Navbar planning */
.page-link {
  text-align: center;
}


/* Planning day */
.dayLabel {
  border: 3px solid white;
  height: 40px;
  background-color: #878080;
  border-radius: 5px;
  min-width: 75px;
  text-align: center;
  font-weight: 600;
  color: white;
}

.dayOtherMonth, .day {
  width: 100%;
  height: 80px;
  border: 2px solid white;
  border-radius: 5px;
}

.day {
  background-color: #efefef;
}

.dayOtherMonth {
  color: rgb(0, 0, 0);
  background-color: rgb(179, 179, 179);
}

#today {
  background-color: rgb(119, 176, 176);
}

.dayNb {
  font-weight: 600;
  margin-left: 5px;
}

.day:hover .dayNb {
  font-weight: 750;
}

.event:hover {
  margin: 2px;
  background-color: green!important;
  cursor: pointer;
}

.clicked {
  cursor: e-resize;
}

.modalDiv .card-text {
  margin: 5px;
}

#modal, #modalForm {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  right: 0;
  top: 0;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

#closeButton{
  z-index: 1; /* Sit on top */
  right: 25%;
  top: 50%;
  margin: 15px;
}